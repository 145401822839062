<template>
  <Main />
</template>

<script>
import Main from "@/layouts/Main.vue";
export default {
  components: { Main }
};
</script>
